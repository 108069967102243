import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSlice } from './slices/auth';

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  },
});

setupListeners(store.dispatch);
