/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  username: '',
  accessToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload: { name } }) => {
      state.isLoggedIn = true;
      state.username = name;
    },
    logout: () => initialState,
  },
});

const authSelector = (state) => state.auth;

export const usernameSelector = createSelector(
  authSelector,
  ({ username }) => username,
);

export const isLoggedInSelector = createSelector(
  authSelector,
  ({ isLoggedIn }) => isLoggedIn,
);

// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
