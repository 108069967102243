const {
  REACT_APP_AWS_COGNITO_CLIENT_ID,
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
} = process.env;

export default {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: REACT_APP_AWS_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,

    userPoolWebClientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
  },
};
