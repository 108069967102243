import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Auth from '@aws-amplify/auth';
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  login,
  logout,
  isLoggedInSelector,
  usernameSelector,
} from '../../lib/store/slices/auth';
import StoreCentralLogo from '../../images/store-central.png';
import './amplify.css';

const AuthStateApp = () => {
  const dispatch = useDispatch();
  const currentUsername = useSelector(usernameSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  React.useEffect(
    () =>
      onAuthUIStateChange((nextAuthState) => {
        switch (nextAuthState) {
          case AuthState.SignedIn:
            Auth.currentAuthenticatedUser().then(({ attributes: { name } }) => {
              dispatch(login({ name }));
            });
            break;
          case AuthState.SignedOut:
            dispatch(logout());
            break;
          default:
        }
      }),
    [],
  );

  return isLoggedIn && currentUsername ? (
    <div className="App">
      <div>Hello, {currentUsername}</div>
      <AmplifySignOut />
    </div>
  ) : (
    <>
      <img height="40px" src={StoreCentralLogo} alt="logo" />
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp />
      </AmplifyAuthenticator>
    </>
  );
};

export default AuthStateApp;
